import React, {useState, useEffect} from 'react';
import { useFirestore } from "react-redux-firebase";
import styles from './OrderItems.module.scss';

const OrderItems = (props) => {

    const firestore = useFirestore();
    var [product, setProduct] = useState({});

    const getProductByOrder = async () => {
        try {
          const docRef = firestore.collection("products").doc(props.id);
          const result = await docRef.get();
          if (result.exists) {
            setProduct(result.data());
          } else {
            alert("somthing went wrong");
          }
        } catch (e) {
          console.log("error", e.message);
        }
      };

      useEffect(() => {
        getProductByOrder();
      }, [props.id]);

    return (
            <div className={`row ${styles.orderItemBox}`}>
            <div className="col-md-4 col-12">
                <img src={product.productImg} alt={product.productName}/>
            </div>
            <div className="col-md-8 col-12">
                <p>{product.productName}</p>
                <p>Price: {product.price}</p>
            </div>
        </div>
    )
}

export default OrderItems;
